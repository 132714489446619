<template>
  <div class="zdd-profile">
    <div class="zdd-profile-header">
      <div class="zdd-profile-header-company">
        <div
          class="zdd-profile-header-company-info"
          @click="handleShowCompanyUpdate"
          v-if="isLogin"
        >
          <div class="zdd-profile-header-company-info-text">
            Hi，<span v-if="isComUser">{{ userInfo.comName }}</span>
            <span v-else>{{ userInfo.name || telephone }}</span>
          </div>
          <span class="arrow"></span>
        </div>
        <div class="zdd-profile-header-company-info" v-else>
          <div
            class="zdd-profile-header-company-info-text"
            @click="handleToLogin"
          >
            请先登录/注册
          </div>
        </div>
        <div class="zdd-profile-header-company-operate" v-if="isLogin">
          <van-button
            plain
            round
            size="small"
            v-if="isComUser && !isComCredit"
            @click.stop="handleToCompanyAuthPage"
            >认证企业</van-button
          >
        </div>
      </div>
      <div class="zdd-profile-header-user">
        <div class="zdd-profile-header-user-avatar">
          <img
            :src="userInfo.headImg"
            alt="avatar"
            v-if="isLogin && userInfo.headImg"
          />
          <img src="@/assets/imgs/default_img.svg" alt="avatar" v-else />
        </div>
        <div class="zdd-profile-header-user-info" @click="handleToInfoPage">
          <div class="zdd-profile-header-user-info-detail">
            <template v-if="activationStatus">
              <div class="zdd-profile-header-user-info-detail__telephone">
                去激活账号
              </div>
            </template>
            <template v-else-if="isLogin">
              <div
                class="zdd-profile-header-user-info-detail__name"
                v-if="userInfo.name"
              >
                {{ userInfo.name }}
              </div>
              <div
                class="zdd-profile-header-user-info-detail__telephone"
                :class="{ large: !userInfo.name }"
              >
                {{ telephone }}
              </div>
            </template>
            <template v-else>
              <div class="zdd-profile-header-user-info-detail__telephone">
                请先登录/注册
              </div>
            </template>
          </div>
          <div class="zdd-profile-header-user-info-operate" v-if="isLogin">
            <van-icon name="arrow" class="icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="zdd-profile-main" v-if="isLogin">
      <div class="zdd-profile-main-charge" v-if="showCharge">
        <div class="zdd-profile-main-charge-info">
          <SvgIcon type="zdd_profile_file" class="icon" />
          <span class="zdd-profile-main-charge-info-count"
            >可用份数 {{ userInfo.leftUmber }}</span
          >
        </div>
        <div class="zdd-profile-main-charge-button" @click="goAccount">
          <SvgIcon type="zdd_profile_charge_button" class="button-icon" />
        </div>
      </div>
      <div class="zdd-profile-main-manage">
        <div
          class="zdd-profile-main-manage-item"
          @click="handleToSealManage"
          v-if="showSignatureManage"
        >
          <div class="zdd-profile-main-manage-item-name">
            <SvgIcon
              type="zdd_profile_seal_manage"
              class="zdd-profile-main-manage-item-name__icon"
            />
            <div class="zdd-profile-main-manage-item-name__text">印章管理</div>
          </div>
          <div class="zdd-profile-main-manage-item-operate">
            <van-icon name="arrow" class="icon" />
          </div>
        </div>
        <div
          class="zdd-profile-main-manage-item"
          @click="handleToSignatureManage"
        >
          <div class="zdd-profile-main-manage-item-name">
            <SvgIcon
              type="zdd_profile_signature_manage"
              class="zdd-profile-main-manage-item-name__icon"
            />
            <div class="zdd-profile-main-manage-item-name__text">签名管理</div>
          </div>
          <div class="zdd-profile-main-manage-item-operate">
            <van-icon name="arrow" class="icon" />
          </div>
        </div>

        <div
          class="zdd-profile-main-manage-item"
          @click.stop="handlePersonal(isComUser ? '企业认证' : '个人认证')"
        >
          <div class="zdd-profile-main-manage-item-name">
            <SvgIcon
              type="renzheng"
              class="zdd-profile-main-manage-item-name__icon"
            />
            <div class="zdd-profile-main-manage-item-name__text">
              {{ isComUser ? '企业认证' : '个人认证' }}
            </div>
          </div>
          <div class="zdd-profile-main-manage-item-operate">
            <span style="font-size: 15px;" v-if="isComUser && !isComCredit"
              >去认证</span
            >
            <van-icon name="arrow" class="icon" />
          </div>
        </div>
      </div>

      <div class="zdd-profile-main-logout">
        <van-button plain @click="handleLogout">退出登录</van-button>
      </div>

      <div
        class="zdd-profile-com-invite"
        v-if="isComUser && isZdd"
        @click="ZddInvitPopup = true"
      >
        <img src="@/assets/imgs/zdd_profile_com_invite.png" alt="" />
      </div>
    </div>

    <div class="zdd-profile-footer" v-if="isZdd">
      <div class="home-link">
        【电脑端访问地址】浏览器搜索 <span class="link">zhidaidai.com</span>
      </div>
    </div>

    <template v-if="isZdd">
      <ZddInvitPopup
        :show="ZddInvitPopup"
        @close="closeInvitPopup"
      ></ZddInvitPopup>
    </template>
    <ZddCompanyUpdate
      v-model="showZddCompanyUpdate"
      :cur-com-id="userInfo.comId"
    />
  </div>
</template>

<script>
  import ZddCompanyUpdate from '@/components/Zdd/ZddCompanyUpdate.vue'
  import ZddInvitPopup from '@/components/Zdd/ZddInvitPopup.vue'
  import { mapGetters } from 'vuex'
  import { isWxMiniProgram } from '@/utils/ua'
  import { miniLogin, navigateToMiniLogin } from '@/utils/mini'

  import { hasAuth } from '@/utils/hasAuth'

  export default {
    name: 'ZddProfile',
    components: { ZddCompanyUpdate, ZddInvitPopup },
    props: {
      isZdd: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showZddCompanyUpdate: false,
        ZddInvitPopup: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'getUserInfo',
        isComUser: 'isComUser',
        isComCredit: 'isComCredit',
      }),
      telephone() {
        if (this.userInfo.telephone) {
          return this.userInfo.telephone.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1****$3'
          )
        }
        return ''
      },
      isLogin() {
        return !!this.$store.getters.getUserInfo.userId
      },

      // 是否展示充值
      showCharge() {
        // zdd 环境下只有企业身份展示
        if (this.isZdd) {
          return this.isComUser
        }
        // yyz 环境下如果是个人就展示，如果是企业则要判断当前用户是否有权限
        if (this.isComUser) {
          // return this.hasAuth(['finance_view'])
          return this.isComUser
        }
        return true
      },

      // 是否展示印章管理
      showSignatureManage() {
        // zdd 环境企业用户展示
        if (this.isZdd) {
          return this.isComUser
        }
        // yyz 需要判断企业用户的权限，个人用户没有签章管理
        if (this.isComUser) {
          return this.hasAuth(['signature_view'])
        }
        return false
      },
      // 账号激活状态 需要激活 true 无需激活 false
      activationStatus() {
        return this.isLogin && !this.userInfo.telephone && !this.userInfo.email
      },
    },
    methods: {
      hasAuth,
      goAccount() {
        // 登录状态且账号未激活，跳转至账号激活页面
        if (this.activationStatus) {
          this.$router.push({
            name: 'qywxAccountActivation',
          })
          return
        }
        this.$router.push({
          path: '/account',
        })
      },
      closeInvitPopup() {
        this.ZddInvitPopup = false
      },
      handleLogout() {
        this.$emit('logout')
      },
      handleToSealManage() {
        this.$emit('toSealManage')
      },
      handleToSignatureManage() {
        // 登录状态且账号未激活，跳转至账号激活页面
        if (this.activationStatus) {
          this.$router.push({
            name: 'qywxAccountActivation',
          })
          return
        }
        this.$emit('toSignatureManage')
      },
      handleToInfoPage(e) {
        // 登录状态且账号未激活，跳转至账号激活页面
        if (this.activationStatus) {
          this.$router.push({
            name: 'qywxAccountActivation',
          })
          return
        }
        if (this.isLogin) {
          this.$emit('toUserInfo')
        } else {
          this.handleToLogin(e)
        }
      },
      handleToCompanyAuthPage() {
        this.$emit('toCompanyAuth')
      },
      handleToCreateCompanyPage() {
        this.$router.push({
          name: 'CreateCompany',
        })
      },
      handleShowCompanyUpdate() {
        // 登录状态且账号未激活，跳转至账号激活页面
        if (this.activationStatus) {
          this.$router.push({
            name: 'qywxAccountActivation',
          })
          return
        }
        this.showZddCompanyUpdate = true
      },
      handleToLogin(e) {
        e.stopPropagation()
        if (isWxMiniProgram()) {
          navigateToMiniLogin(true, '/My')
        } else {
          this.$router.push({
            name: 'Login',
          })
        }
      },
      // 个人认证入口
      handlePersonal(name) {
        if (name === '企业认证') {
          this.$emit('toCompanyAuth')
        } else {
          // console.log('个人认证');
          this.$emit('toHandleAuth')
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: #f3f3f3;
    padding-bottom: 50px;
    .zdd-profile-header {
      height: 161px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px 16px 0;
      background-image: url('../../assets/imgs/zdd_profile_header_bg.png');
      background-size: 100% 100%;
      .zdd-profile-header-company {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .zdd-profile-header-company-info {
          font-size: 16px;
          font-weight: 500;
          color: @WHITE;
          line-height: 16px;
          display: flex;
          align-items: center;
          .arrow {
            margin-left: 7px;
            display: inline-flex;
            align-items: center;
            border-top: 5px solid @WHITE;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 0;
          }
        }
        .zdd-profile-header-company-operate {
          display: flex;
          .van-button {
            background: transparent;
            color: @WHITE;
            height: 26px;
          }
        }
      }
      .zdd-profile-header-user {
        margin-top: 21px;
        width: 100%;
        display: flex;
        flex: 1;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        padding: 18px 16px 24px;
        .zdd-profile-header-user-avatar {
          border-radius: 50%;
          width: 47px;
          height: 47px;
          img {
            border: 1px solid #e4e4e4;
            width: 47px;
            height: 47px;
            border-radius: 50%;
          }
        }
        .zdd-profile-header-user-info {
          width: 100%;
          height: 100%;
          margin-left: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .zdd-profile-header-user-info-detail {
            display: flex;
            flex-direction: column;
            .zdd-profile-header-user-info-detail__name {
              font-size: 17px;
              font-weight: 500;
              color: #1d0805;
              line-height: 12px;
              margin-bottom: 12px;
            }
            .zdd-profile-header-user-info-detail__telephone {
              font-size: 13px;
              font-weight: 400;
              color: #4b505f;
              line-height: 12px;
              &.large {
                font-size: 17px;
                font-weight: 500;
              }
            }
          }
          .zdd-profile-header-user-info-operate {
            display: flex;
            align-items: center;
            .icon {
              font-size: 12px;
            }
          }
        }
      }
    }
    .zdd-profile-main {
      padding: 0 16px;
      .zdd-profile-main-charge {
        padding: 13px 16px;
        background: linear-gradient(
          125deg,
          #faddb2 0%,
          #fce9d2 20%,
          #f4cd98 89%
        );
        border-radius: 0 0 4px 28px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .zdd-profile-main-charge-info {
          display: flex;
          align-items: center;
          .icon {
            font-size: 18px;
          }
          .zdd-profile-main-charge-info-count {
            margin-left: 6px;
            font-size: 15px;
            font-weight: 500;
            color: #5f3809;
            line-height: 21px;
          }
        }
        .zdd-profile-main-charge-button {
          display: flex;
          align-items: center;
          .button-icon {
            display: inline-flex;
            height: 26px;
            line-height: 1;
            font-size: 78px;
            font-style: normal;
            /deep/ svg {
              height: 26px;
            }
          }
        }
      }
      .zdd-profile-main-manage {
        width: 100%;
        margin: 12px 0;
        background: @WHITE;
        border-radius: 4px;
        padding: 0 16px;
        .zdd-profile-main-manage-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 0;
          .zdd-profile-main-manage-item-name {
            display: flex;
            align-items: center;
            .zdd-profile-main-manage-item-name__icon {
              font-size: 19px;
            }
            .zdd-profile-main-manage-item-name__text {
              margin-left: 7px;
              font-size: 15px;
              font-weight: 400;
              color: #333333;
              line-height: 21px;
            }
          }
          .zdd-profile-main-manage-item-operate {
            display: flex;
            align-items: center;
            line-height: 21px;
            span {
              margin-right: 5px;
            }
            .icon {
              font-size: 11px;
              color: #666666;
            }
          }
        }
        .zdd-profile-main-manage-item + .zdd-profile-main-manage-item {
          border-top: 1px solid #f3f3f3;
        }
      }
      .zdd-profile-main-logout {
        display: flex;
        width: 100%;
        .van-button {
          width: 100%;
        }
      }
      .zdd-profile-com-invite {
        margin-top: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .zdd-profile-footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 16px 32px;
      font-size: 14px;
      text-align: center;
      color: #4b505f;
      .home-link {
        .link {
          user-select: all;
          color: var(--main-color);
        }
      }
    }
  }
</style>
