<template>
  <div class="my-container">
    <ZddProfile :isZdd="isZdd" @logout="handleQuit" @toSealManage="goSealManage" @toSignatureManage="goSignManage"
      @toUserInfo="handleGoInfo" @toCompanyAuth="handleCompanyAuth" @toHandleAuth="handleAuth" />
    <!--     <div class="content" v-else>
      <div class="item-user-info">
        <van-cell is-link @click="handleGoInfo">
          <template #icon>
            <div class="header-img">
              <van-icon
                :name="
                  userInfo.headImg || require('@/assets/imgs/default_img.svg')
                "
              />
            </div>
          </template>
<template #title>
            <div class="isRealname">
              <span
                :class="userInfo.creditStatus === 1 ? 'custom-title' : ''"
                >{{
                  userInfo.creditStatus === 1 ? userInfo.name : '未实名'
                }}</span
              >
            </div>
            <div class="phone-num">
              <span>{{ userInfo.telephone }}</span>
            </div>
          </template>
<template v-if="userInfo.creditStatus !== 1" #default>
            <div class="realname">
              <span @click.stop="handleAuth">实名认证</span>
            </div>
          </template>
</van-cell>
</div>
<van-cell-group v-if="userInfo.comId" :border="false" class="item-company">
  <div class="company-status">
    <div class="card">
      <div>
        <van-cell title="当前身份：企业身份" @click="handleSwitch">
          <template #extra>
                  <van-icon
                    :name="require('@/assets/imgs/switch.png')"
                    class="search-icon"
                  />
                  <span class="switch-text">切换身份</span>
                </template>
        </van-cell>
      </div>
      <div class="company-list">
        <van-cell :title="userInfo.comName">
          <template #extra>
                  <span
                    v-if="isComCreditSuccess !== 1 && currentIsCreator"
                    class="register-button"
                    @click="handleCompanyAuth"
                    >去认证</span
                  >
                  <span
                    v-else-if="isComCreditSuccess === 1"
                    class="register-button-success"
                  >
                    <van-icon
                      :name="require('@/assets/imgs/su.png')"
                      class="search-icon"
                    />
                    已认证
                  </span>
                  <span v-else class="register-button-join">已加入</span>
                </template>
        </van-cell>
      </div>
    </div>
  </div>
  <van-cell title="企业文件份数" is-link @click="goAccount" :value="accountInfo && accountInfo.leftUmber"
    v-if="hasAuth(['finance_view'])" />
  <van-cell title="印章管理" is-link @click="goSealManage" v-if="hasAuth(['finance_view'])" />
  <van-cell title="签名管理 " is-link @click="goSignManage" />
</van-cell-group>
<van-cell-group v-else :border="false" class="item-personal">
  <van-cell title="当前身份：个人身份" value="切换" is-link @click="handleSwitch" />
  <van-cell title="个人文件份数" :value="accountInfo && accountInfo.leftUmber" is-link @click="goAccount" />
  <van-cell title="签名管理" is-link @click="goSignManage" />
</van-cell-group>
<div class="spacing"></div>
<van-cell-group :border="false">
  <van-cell :border="true" title="设置登录密码" is-link @click="goSetPassword" v-if="userInfo.isNewRegist" />
  <van-cell :border="true" title="退出登录" is-link @click="handleQuit" />
  <van-cell />
</van-cell-group>
</div> -->
  </div>
</template>

<script>
import ZddProfile from '@/components/Zdd/ZddProfile.vue'
import { mapState, mapGetters } from 'vuex'
import accountApi from '@/api/account'
import creaditApi from '@/api/creadit'
import { Dialog } from 'vant'
import getCreditToken from '@/utils/getCreditToken'
import { hasAuth } from '@/utils/hasAuth'
import { inviteShare } from '@/utils/zdd'
import { isWxMiniProgram } from '@/utils/ua'
import { navigateToMiniLogin, postShareMessageToMini } from '@/utils/mini'
import { hasCookies } from '@/utils/cookies'

export default {
  components: { ZddProfile },
  data() {
    return {
      accountInfo: null,
      isComCreditSuccess: 0,
      currentIsCreator: false,
    }
  },
  created() { },
  computed: {
    ...mapState({
      systemInfo: state => state.system,
      userInfo: state => state.user.userInfo,
    }),
    isZdd() {
      return this.$store.getters['system/isZdd']
    },
    isLogin() {
      return !!this.$store.getters.getUserInfo.userId
    },
  },
  mounted() {
    if (hasCookies()) {
      if (this.userInfo.comId) {
        this.$store.commit('sign/clearSignMsg')
        this.getAccountInfo()
        this.getComCreditInfo()
      } else {
        this.getPersonAccountInfo()
      }
    }

    // 小程序分享
    if (isWxMiniProgram()) {
      postShareMessageToMini({
        title: '易云章',
        content: '',
        url: encodeURIComponent(this.$route.path),
      })
    }
  },
  methods: {
    goSetPassword() {
      this.$router.push('SetPassword')
    },
    hasAuth,
    getAccountInfo() {
      const auth = this.hasAuth(['finance_view'])

      if (auth) {
        console.log(auth)
        accountApi
          .getAccountInfo({
            identity: this.userInfo.comId ? 1 : 0,
          })
          .then(res => {
            this.accountInfo = res
          })
      }
    },
    getComCreditInfo() {
      this.loading = true
      creaditApi
        .getComCreditInfo()
        .then(res => {
          this.isComCreditSuccess = res.creditStatus
          this.currentIsCreator = res.currentIsCreator
        })
        .catch(err => {
          // this.$message({
          //   message: err,
          //   type: 'error'
          // });
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPersonAccountInfo() {
      accountApi
        .getPersonAccountInfo({
          identity: this.userInfo.comId ? 1 : 0,
        })
        .then(res => {
          this.accountInfo = res
        })
    },
    goAccount() {
      this.$router.push('account')
    },
    goSealManage() {
      this.$router.push('sealManage')
    },
    goSignManage() {
      this.$router.push('signManage')
    },
    // 个人信息
    handleGoInfo() {
      this.$router.push('MyInfo')
    },

    // 切换身份
    handleSwitch() {
      this.$router.push('SwitchStatus')
    },

    // 实名认证
    async handleAuth() {
      const type = 2
      const token = await getCreditToken(type)
      if (token) {
        this.$router.push({
          name: 'nameAndIdentitycard',
          query: {
            token,
          },
        })
      }
    },

    // 企业认证
    async handleCompanyAuth() {
      
      this.$store.commit('setComSignUrl', '/My')
      this.$router.push('AuthSubmit')
    },

    // 退出登录
    handleQuit() {
      console.log('退出登录');
      Dialog.confirm({
        title: '提示',
        message: `退出后无法使用${this.systemInfo.company}产品功能，是否确定退出`,
      })
        .then(async () => {
          await this.$store.dispatch('logout')
          if (isWxMiniProgram()) {
            // 不需要做额外的操作
            window.wx.ready(() => {
              // 在小程序环境下需要向小程序通知清除当前的 cookie 信息
              window.wx.miniProgram.postMessage({
                data: {
                  type: 'clearSession',
                },
              })
            })
          } else {
            this.$router.replace('Login')
          }
        })
        .catch(() => [])
    },

    // 暂未开放
    onNotOpen() {
      Dialog.alert({
        title: '提示',
        message: '功能暂未开放请敬请期待',
        confirmButtonColor: '#111A34',
      }).then(() => { })
    },

    handleToSharePage() {
      inviteShare()
    },
  },
}
</script>

<style lang="less" scoped>
.my-container {
  width: 100%;
  height: 100%;

  .content {
    padding: 15px 0;
  }

  .item-user-info {
    .van-cell {
      display: flex;
      align-items: center;
      padding: 16px 16px 18px;
    }

    .header-img {
      align-self: flex-start;
      padding-right: 17px;

      .van-icon {
        width: 55px;
        height: 55px;
        border-radius: 27.5px;

        img {
          width: 55px;
          height: 55px;
          border-radius: 27.5px;
          object-fit: cover;
        }
      }
    }

    .phone-num {
      font-size: 14px;
      color: #111a34;
    }

    .isRealname {
      font-size: 16px;
      font-weight: 500;
      color: #eb5050;

      .custom-title {
        color: #111a34;
      }
    }

    .realname {
      span {
        display: inline-block;
        text-align: center;
        width: 62px;
        height: 24px;
        background: #1676ff;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .spacing {
    height: 8px;
    background: #f4f5f6;
  }

  .item-personal {
    .van-cell {
      padding: 12px 16px;
    }

    .van-cell__value {
      color: #111a34;
    }

    .van-icon-arrow::before {
      color: #111a34;
    }
  }

  .item-company {
    .company-status {
      padding: 5px 16px 16px 12px;

      .card {
        border: 1px solid #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 8px 0px rgba(231, 231, 231, 0.5);

        .van-cell {
          display: flex;
          align-items: center;
        }

        .van-icon {
          margin-right: 7px;
        }

        .switch-text {
          color: #1676ff;
        }

        .search-icon {
          margin-bottom: 2px;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }

      .van-cell__value {
        color: #677283;
        flex: none;
      }

      .company-list {
        .van-cell__title {
          color: #111a34;
          font-weight: 500;
        }

        .register-button {
          display: inline-block;
          text-align: center;
          width: 62px;
          height: 24px;
          background: #1676ff;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
        }

        .register-button-success {
          width: 67px;
          height: 20px;
          border: 1px solid #fe815b;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #fe815b;
          text-align: center;
          line-height: 20px;

          .van-icon {
            margin: 0;
          }
        }

        .register-button-join {
          color: #6d7790;
        }
      }
    }

    .van-cell__value {
      color: #111a34;
    }
  }

  .van-icon-arrow::before {
    color: #111a34;
  }

  .van-cell__title {
    color: #677283;
  }

  .zdd-my-invite {
    width: 100%;
    height: 78px;
    padding: 0 8px;

    .zdd-my-invite-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
